import {StatusBar} from 'expo-status-bar';
import { Asset, useAssets } from 'expo-asset';
import {
    ActivityIndicator,
    Button,
    Image,
    Linking,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View,

} from 'react-native';
import {useState} from "react";
import BoxOfEquipment from "./BoxOfEquipment";
import Logo from "./Logo";
import ShopNowBox from "./ShopNowBox";
import UserParamQuestions from "./UserParamQuestions";

export default function SearchView() {
    const [assets, assetsError] = useAssets([require('../assets/BoxOfEquipments.png'),
                                                      require('../assets/hockey_stick_general.png'),
                                                      require('../assets/amazon-brand-icon.png'),
                                                      ]);
    const [sport, setSport] = useState('');
    const [showQuestions, setShowQuestions] = useState(false);
    const [item, setItem] = useState('');
    const [level, setLevel] = useState('');
    const [position, setPosition] = useState('');
    const [weight, setWeight] = useState(160);
    const [height, setHeight] = useState(6);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const prepareLinks = (data) => {

        console.log(data);
        let response = data.result.text;
        console.log(response);

        let array = [];
        try {
            array = JSON.parse(response);
        } catch (error) {
            console.log("cant parse response: ", response);
        }
        return array;
    }
    const startSearch = () => {
        const data = {
            sport: sport,
            item: item,
            level: level,
            position: position,
            weight: weight,
            height: height,
        };

        setLoading(true);
        // fetch('http://127.0.0.1:5001/equipme-58c63/us-central1/searchEquip', {
        // fetch('https://us-central1-equipme-58c63.cloudfunctions.net/searchEquip', {
            // fetch('http://127.0.0.1:5001/equipme-58c63/us-central1/searchEquip', {
            fetch('/api/searchEquip', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                setResults(prepareLinks(data));
                console.log(data);
                setLoading(false);
            }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    return (
        <View style={styles.container1}>
            <BoxOfEquipment small={showQuestions} assets={assets}/>
            { !showQuestions &&
                <ShopNowBox onPressLearnMore={ (e) => setShowQuestions(true)} />
            }
            { showQuestions &&
                <UserParamQuestions assets={assets} onResults={ (results) => setResults(results) }/>
            }
        </View>
    );
}

const PreviewLayout = ({
                           label,
                           children,
                           values,
                           selectedValue,
                           setSelectedValue,
                       }) => (
    <View style={{minWidth: 400}}>
        <Text style={styles.label}>{label}</Text>
        <View style={styles.row}>
            {values.map(value => (
                <TouchableOpacity
                    key={value}
                    onPress={() => setSelectedValue(value)}
                    style={[styles.button, selectedValue === value && styles.selected]}>
                    <Text
                        style={[
                            styles.buttonLabel,
                            selectedValue === value && styles.selectedLabel,
                        ]}>
                        {value}
                    </Text>
                </TouchableOpacity>
            ))}
        </View>
        <View style={[styles.container2, {[label]: selectedValue}]}>{children}</View>
    </View>
);


const InputNumber = ({name, endName, input, setInput}) => {
    return (
        <View style={{
            // paddingHorizontal: 10,
            padding: 10,
            flexDirection: 'row',
            alignSelf: 'flex-start',
        }}>
            <Text style={{
                marginTop: 6,
                fontSize: 16,
                fontWeight: '100',
            }}>{name}</Text>
            <TextInput
                value={input}
                style={{
                    borderBottomWidth: 1,
                    paddingVertical: 3,
                    width: 50,
                    textAlign: 'center',
                }}
                onChangeText={fB =>
                    setInput((isNaN(parseInt(fB, 10)) ? 0 : parseInt(fB, 10)))
                }
            />
            <Text style={{
                marginTop: 6,
                fontSize: 16,
                fontWeight: '100',
            }}>{endName}</Text>
        </View>
    );
};


const Items = ({search, items, loading}) => {
    //     {
    //         "model": "CCM Super Tacks AS3 Pro",
    //         "weight": "490g",
    //         "length": "63 inches",
    //         "brand": "CCM Hockey"
    //     },
    const handlePress = (item) => {
        let url = "https://www.amazon.com/s?k=" + search +" " + item.brand+ " " + item.model+ " " + item.length + " " + item.weight;
        Linking.canOpenURL(url).then((supported) => {
            return Linking.openURL(url);
        })
    }
    return (
        <View style={{minWidth: 400}}>
            {loading && <ActivityIndicator size="large"/>}
            {items && items.length > 0 &&
                <Text style={stylesLink.resultsText}> We found for you {items.length} best matches! Check them
                    out.</Text>
            }
            {items.map(
                (item) =>
                    <View key={item.model} style={stylesLink.container}>
                        <Image
                            style={stylesLink.tinyLogo}
                            source={{
                                uri: 'https://1000logos.net/wp-content/uploads/2016/10/Amazon-logo-meaning.jpg',
                            }}
                        />
                        <View style={stylesLink.containerDescription}>
                            <Text
                                style={stylesLink.linkDescription}> {item.brand}{", "}{item.model}{", "}{item.length}{", "}{item.weight} </Text>
                            <Button title={"Link"} onPress={ () => handlePress(item)}/>
                        </View>
                    </View>
            )}
        </View>
    );
}


const stylesLink = StyleSheet.create({
    tinyLogo: {
        width: 70,
        height: 70,
        borderRadius: 10,
    },
    resultsText: {
        textAlign: 'center',
        marginBottom: 5,
        fontSize: 20,
    },
    linkDescription: {
        textAlign: 'left',
        margin: 5,
        fontSize: 16,
    },
    containerDescription: {
        flex: 1,
        flexDirection: "column"
    },
    container: {
        marginBottom: 5,
        flex: 1,
        borderStyle: "solid",
        borderColor: "black",
        borderRadius: 10,
        borderWidth: 1,
        backgroundColor: '#fff',
        flexDirection: "row",
        alignItems: 'left',
        justifyContent: 'center',
    }
});

const styles = StyleSheet.create({
    boxWithEquipment: {
        width: 274,
        height: 257
    },
    logoBig: {
        marginBottom: 20,
        // backgroundColor: '#2b2b2b',
    },
    container1: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    container2: {
        // flex: 1,
        marginTop: 8,
        // backgroundColor: 'aliceblue',
        // minHeight: 200,
    },
    box: {
        width: 50,
        height: 50,
    },
    row: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    buttonSearch: {
        paddingHorizontal: 8,
        paddingVertical: 6,
        borderRadius: 4,
        backgroundColor: 'lightblue',
        // alignSelf: 'flex-start',
        marginHorizontal: '1%',
        marginBottom: 6,
        // minWidth: '48%',
        textAlign: 'center',
    },

    button: {
        paddingHorizontal: 8,
        paddingVertical: 6,
        borderRadius: 4,
        backgroundColor: 'oldlace',
        alignSelf: 'flex-start',
        marginHorizontal: '1%',
        marginBottom: 6,
        minWidth: '48%',
        textAlign: 'center',
    },
    searchButtonLabel: {
        fontSize: 12,
        fontWeight: '500',
        color: 'blue',
    },
    selected: {
        backgroundColor: 'coral',
        borderWidth: 0,
    },
    buttonLabel: {
        fontSize: 12,
        fontWeight: '500',
        color: 'coral',
    },
    selectedLabel: {
        color: 'white',
    },
    label: {
        textAlign: 'center',
        marginBottom: 10,
        fontSize: 24,
    },
});
