import { useAssets } from 'expo-asset';
import {
    Button,
    Image,
    StyleSheet, Text,
    View,

} from 'react-native';

export default function ShopNowBox({onPressLearnMore}) {
    return (
        <View style={styles.contaier}>
            <Text style={styles.baseText}>
                <Text style={styles.titleText}
                      // onPress={onPressTitle}
                >
                    {"Your start line\n" +
                        "is here, Let’s GO!"}
                    {'\n'}
                    {'\n'}
                </Text>
                <Text style={styles.text} numberOfLines={6}>{"A newbie who doesn’t know where to start with his new sport? Or a world champion who’s looking forward to level up his game? Whoever you are, we got you the equipment you need!"}</Text>
            </Text>
            <button
                style={styles.button}
                onClick={onPressLearnMore}
                // title="Start now"
                // color="#FFC90B"
                // accessibilityLabel=""
            >{"Start now"}</button>
        </View>
    );
}

const styles = StyleSheet.create({
    button: {
        marginTop: 12,
        width: 151,
        height: 40,
        backgroundColor: '#FFC90B',
        boxShadow: '0px 8px 22px rgba(157, 128, 26, 0.2)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        fontWeight: 700,
        fontSize: 18,
        lineHeight: 24,
        color: '#FFFFFF',
        borderStyle: 'undefined',
        borderColor: '#FFC90B',
    },
    baseText: {
        width: 292,
        fontFamily: 'sans-serif',
        fontStyle: "normal",
        color: '#454A5F',
    },
    titleText: {
        fontSize: 24,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
    },
    text: {
        lineHeight: 20,
        fontSize: 14,
        textAlign: 'justify',
        fontWeight: 400,
    }
});
