import { useAssets } from 'expo-asset';
import {
    Image,
    StyleSheet, Text,
    View,

} from 'react-native';
import Logo from "./Logo";

export default function BoxOfEquipment({small, assets}) {
    return (
        <View style={styles.container}>
            <Logo style={styles.logoMobile}/>
            {assets && <Image style={ {...styles.boxTransition, ...(small ? styles.boxWithEquipment2 : styles.boxWithEquipment1)}
            } source={assets[0]}></Image>}
            <Text style={{...styles.text1, ...styles.text1Position, ...(small ? styles.text1Pos2 : styles.text1Pos1 )}}>{"Satisfied athletes!"}</Text>
            <Text style={{...styles.text1, ...styles.text2Position, ...(small ? styles.text2Pos2 : styles.text2Pos1 )}}>{"Specialized in sports!"}</Text>
            <Text style={{...styles.text1, ...styles.text3Position, ...(small ? styles.text3Pos2 : styles.text3Pos1 )}}>{"Shop over 5 shops"}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
      marginTop:70,
        transition: '1s'

    },
    text1Position : {
        position: 'absolute',
        width: 90,
        height: 40,
    },
    text1Pos1 : {
        left: -30,
        top: 95,
    },
    text1Pos2 : {
        left: -60,
        top: 75,
    },
    text3Position : {
        position: 'absolute',
        width: 77,
        height: 36,
    },
    text3Pos1 : {
        left: 71,
        bottom: 39,
    },
    text3Pos2 : {
        left: 50,
        bottom: 0,
    },
    text2Position : {
        position: 'absolute',
        width: 89,
        height: 40,
    },
    text2Pos1 : {
        right: -0.3,
        top: 71.34,
    },
    text2Pos2 : {
        right: -40,
        top: 22
    },
    text1 :{
        backgroundColor: '#FFFFFF',
        paddingVertical: 8,
        paddingHorizontal: 12,
        background: '#FFFFFF',
        boxShadow: "-2px 6px 12px rgba(0, 0, 0, 0.2)",
        borderRadius: 6.52369,

        // fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 10,
        lineHeight: 12,

        textAlign: 'center',

        color: '#FFB001',
        transition: '1s'

    },
    boxTransition: {
        transition: '1s'
    },
    boxWithEquipment1: {
        width: 274,
        height: 257
    },
    boxWithEquipment2: {
        width: 196,
        height: 184
    },
});
