import {useAssets} from 'expo-asset';
import {
    Button,
    Image, Linking,
    StyleSheet, Text,
    View,

} from 'react-native';
import SportTile from "./SportTile";
import {useState} from "react";
import SportTileEquipment from "./SportTileEquipment";
import {SelectList} from "react-native-dropdown-select-list/index";
import Items from "./Items";

export default function UserParamQuestions({assets, onResults}) {
    const [step1, setStep1] = useState({textBold: "", text: "Pick a sport", selected: false});
    const [step2, setStep2] = useState({textBold: "", text: "Pick your equipment", selected: false});
    const [step3, setStep3] = useState({textBold: "", text: "Complete your parameters", selected: false});
    const [level, setLevel] = useState("");
    const [postition, setPosition] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [searchDone, setSearchDone] = useState(false);
    const [results, setResults] = useState([
    ]);
    const [loading, setLoading] = useState(false);
    const openUrl = async () => {
        let url = "https://www.figma.com/proto/FW2o5SyoatYk8GMY8fJ5J0/Equipify-(1)?node-id=128%3A200&scaling=min-zoom&page-id=128%3A199";
        window.open(url, "_blank")
    }

    const selectLevel = [
        {key:'1', value:'Level', disabled:true},
        {key:'2', value:'Beginner'},
        {key:'3', value:'Pro'},
    ]
    const selectPosition = [
        {key:'1', value:'Position', disabled:true},
        {key:'2', value:'Defender'},
        {key:'3', value:'Attacker'}
    ]
    const selectHeight = [
        {key:'1', value:'Height', disabled:true},
        {key:"2", value: "4\' feet" },
        {key:"3", value: "4\'1 feet" },
        {key:"4", value: "4\'2 feet" },
        {key:"5", value: "4\'3 feet" },
        {key:"6", value: "4\'4 feet" },
        {key:"7", value: "4\'5 feet" },
        {key:"8", value: "4\'6 feet" },
        {key:"9", value: "4\'7 feet" },
        {key:"10", value: "4\'8 feet" },
        {key:"1", value: "4\'9 feet" },
        {key:"1", value: "4\'10 feet" },
        {key:"1", value: "4\'11 feet" },
        {key:"1", value: "5\' feet" },
        {key:"1", value: "5\'1 feet" },
        {key:"1", value: "5\'2 feet" },
        {key:"1", value: "5\'3 feet" },
        {key:"1", value: "5\'4 feet" },
        {key:"1", value: "5\'5 feet" },
        {key:"1", value: "5\'6 feet" },
        {key:"1", value: "5\'7 feet" },
        {key:"1", value: "5\'8 feet" },
        {key:"1", value: "5\'9 feet" },
        {key:"1", value: "5\'10 feet" },
        {key:"1", value: "5\'11 feet" },
        {key:"1", value: "6\' feet" },
        {key:"1", value: "6\'1 feet" },
        {key:"1", value: "6\'2 feet" },
        {key:"1", value: "6\'3 feet" },
        {key:"1", value: "6\'4 feet" },
        {key:"1", value: "6\'5 feet" },
        {key:"1", value: "6\'6 feet" },
        {key:"1", value: "6\'7 feet" },
        {key:"1", value: "6\'8 feet" },
        {key:"1", value: "6\'9 feet" },
        {key:"1", value: "6\'10 feet" },
        {key:"1", value: "6\'11 feet" },
        {key:"1", value: "7\' feet" }
    ]

    const selectWeight = [
        { key:'1', value:'Weight', disabled:true},
        { key:"1", value: "100 lbs" },
        { key:"1", value: "102 lbs" },
        { key:"1", value: "104 lbs" },
        { key:"1", value: "106 lbs" },
        { key:"1", value: "108 lbs" },
        { key:"1", value: "110 lbs" },
        { key:"1", value: "112 lbs" },
        { key:"1", value: "114 lbs" },
        { key:"1", value: "116 lbs" },
        { key:"1", value: "118 lbs" },
        { key:"1", value: "120 lbs" },
        { key:"1", value: "122 lbs" },
        { key:"1", value: "124 lbs" },
        { key:"1", value: "126 lbs" },
        { key:"1", value: "128 lbs" },
        { key:"1", value: "130 lbs" },
        { key:"1", value: "132 lbs" },
        { key:"1", value: "134 lbs" },
        { key:"1", value: "136 lbs" },
        { key:"1", value: "138 lbs" },
        { key:"1", value: "140 lbs" },
        { key:"1", value: "142 lbs" },
        { key:"1", value: "144 lbs" },
        { key:"1", value: "146 lbs" },
        { key:"1", value: "148 lbs" },
        { key:"1", value: "150 lbs" },
        { key:"1", value: "152 lbs" },
        { key:"1", value: "154 lbs" },
        { key:"1", value: "156 lbs" },
        { key:"1", value: "158 lbs" },
        { key:"1", value: "160 lbs" },
        { key:"1", value: "162 lbs" },
        { key:"1", value: "164 lbs" },
        { key:"1", value: "166 lbs" },
        { key:"1", value: "168 lbs" },
        { key:"1", value: "170 lbs" },
        { key:"1", value: "172 lbs" },
        { key:"1", value: "174 lbs" },
        { key:"1", value: "176 lbs" },
        { key:"1", value: "178 lbs" },
        { key:"1", value: "180 lbs" },
        { key:"1", value: "182 lbs" },
        { key:"1", value: "184 lbs" },
        { key:"1", value: "186 lbs" },
        { key:"1", value: "188 lbs" },
        { key:"1", value: "190 lbs" },
        { key:"1", value: "192 lbs" },
        { key:"1", value: "194 lbs" },
        { key:"1", value: "196 lbs" },
        { key:"1", value: "198 lbs" },
        { key:"1", value: "200 lbs" },
        { key:"1", value: "202 lbs" },
        { key:"1", value: "204 lbs" },
        { key:"1", value: "206 lbs" },
        { key:"1", value: "208 lbs" },
        { key:"1", value: "210 lbs" },
        { key:"1", value: "212 lbs" },
        { key:"1", value: "214 lbs" },
        { key:"1", value: "216 lbs" },
        { key:"1", value: "218 lbs" },
        { key:"1", value: "220 lbs" },
        { key:"1", value: "222 lbs" },
        { key:"1", value: "224 lbs" },
        { key:"1", value: "226 lbs" },
        { key:"1", value: "228 lbs" },
        { key:"1", value: "230 lbs" },
        { key:"1", value: "232 lbs" },
        { key:"1", value: "234 lbs" },
        { key:"1", value: "236 lbs" },
        { key:"1", value: "238 lbs" },
        { key:"1", value: "240 lbs" },
        { key:"1", value: "242 lbs" },
        { key:"1", value: "244 lbs" },
        { key:"1", value: "246 lbs" },
        { key:"1", value: "248 lbs" },
        { key:"1", value: "250 lbs" },
        { key:"1", value: "252 lbs" },
        { key:"1", value: "254 lbs" },
        { key:"1", value: "256 lbs" },
        { key:"1", value: "258 lbs" },
        { key:"1", value: "260 lbs" },
        { key:"1", value: "262 lbs" },
        { key:"1", value: "264 lbs" },
        { key:"1", value: "266 lbs" },
        { key:"1", value: "268 lbs" },
        { key:"1", value: "270 lbs" },
        { key:"1", value: "272 lbs" },
        { key:"1", value: "274 lbs" },
        { key:"1", value: "276 lbs" },
        { key:"1", value: "278 lbs" },
        { key:"1", value: "280 lbs" },
        { key:"1", value: "282 lbs" },
        { key:"1", value: "284 lbs" },
        { key:"1", value: "286 lbs" },
        { key:"1", value: "288 lbs" },
        { key:"1", value: "290 lbs" },
        { key:"1", value: "292 lbs" },
        { key:"1", value: "294 lbs" },
        { key:"1", value: "296 lbs" },
        { key:"1", value: "298 lbs" },
        { key:"1", value: "300 lbs" }
    ]
    const prepareLinks = (data) => {

        console.log(data);
        let response = data.result.text;
        console.log(response);

        let array = [];
        try {
            array = JSON.parse(response);
        } catch (error) {
            console.log("cant parse response: ", response);
        }
        return array;
    }
    const startSearch = (sport, item, level, position, weight, height) => {
        const data = {
            sport: sport,
            item: item,
            level: level,
            position: position,
            weight: weight,
            height: height,
        };

        setLoading(true);
        // fetch('http://127.0.0.1:5001/equipme-58c63/us-central1/searchEquip', {
        // fetch('https://us-central1-equipme-58c63.cloudfunctions.net/searchEquip', {
        // fetch('http://127.0.0.1:5001/equipme-58c63/us-central1/searchEquip', {
        fetch('/api/searchEquip', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                let prepareLinks1 = prepareLinks(data);
                setResults(prepareLinks1);
                onResults(prepareLinks1);
                console.log(data);
                setLoading(false);
            }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    function search() {
        setStep3({
            textBold: level + ", " + postition + ", " + weight + ", " + height,
            text: " selected",
            selected: true
        });
        setSearchDone(true);
        startSearch("Hockey", "Hockey stick", level, postition, weight, height);
    }

    return (
        <View>
            <View style={styles.titleContainer}>
                <Text style={styles.baseText}>
                    <Text style={styles.titleText}
                    >
                        {"Search for Equipment"}
                    </Text>
                </Text>
            </View>
            {results.length === 0 && <>
            <View>
                <View style={styles.stepsLink}/>
                <View style={styles.stepContainer}>
                    <Text style={{...styles.number, ...(step1.selected ? styles.numberSelected : styles.numberWaiting)}}
                    >
                        {"1"}
                    </Text>
                    <Text style={styles.stepText}>{"Step 1"}</Text>
                </View>
                <View style={styles.stepTextContainer}>
                    <Text
                        style={{...styles.stepBold, ...(step1.selected ? styles.stepDecriptionSelected : {})}}>{step1.textBold}</Text>
                    <Text
                        style={{...styles.stepDescription, ...(step1.selected ? styles.stepDecriptionSelected : {})}}>{step1.text}</Text>
                </View>
                <View style={{...styles.tilesContainer, ...(step1.selected ? styles.hideHight : {height: 85})}}>
                    <SportTile
                        onPress={() => setStep1({textBold: "Hockey", text: " selected", selected: true})}></SportTile>
                </View>
            </View>
            <View>
                <View style={{...styles.stepsLink, ...(!step1.selected ? styles.stepsLinkGrey : {})}}/>
                <View style={styles.stepContainer}>
                    <Text style={{
                        ...styles.number, ...(step2.selected ? styles.numberSelected : {}),
                        ...(step1.selected && !step2.selected ? styles.numberWaiting : {}),
                        ...(!step1.selected ? styles.numberGray : {})
                    }}>{"2"}</Text>
                    <Text style={styles.stepText}>{"Step 2"}</Text>
                </View>
                <View style={styles.stepTextContainer}>
                    <Text
                        style={{...styles.stepBold, ...(step2.selected ? styles.stepDecriptionSelected : {})}}>{step2.textBold}</Text>
                    <Text
                        style={{...styles.stepDescription, ...(step2.selected ? styles.stepDecriptionSelected : {})}}>{step2.text}</Text>
                </View>
                <View style={{...styles.tilesContainer, ...(!step1.selected || step2.selected ? styles.hideHight : {height: 85})}}>
                    <SportTileEquipment onPress={() => setStep2({
                        textBold: "Hockey stick",
                        text: " selected",
                        selected: true
                    })}></SportTileEquipment>
                </View>
            </View>
            <View>
                <View style={styles.stepContainer}>
                    <Text style={{
                        ...styles.number, ...(step3.selected ? styles.numberSelected : {}),
                        ...(step2.selected && !step3.selected ? styles.numberWaiting : {}),
                        ...(!step2.selected ? styles.numberGray : {})
                    }}>{"3"}</Text>
                    <Text style={styles.stepText}>{"Step 3"}</Text>
                </View>
                <View style={styles.stepTextContainer}>
                    <Text
                        style={{...styles.stepBold, ...(step3.selected ? styles.stepDecriptionSelected : {})}}>{step3.textBold}</Text>
                    <Text
                        style={{...styles.stepDescription, ...(step3.selected ? styles.stepDecriptionSelected : {})}}>{step3.text}</Text>
                </View>
                <View style={{...styles.tilesContainer, ...(!step2.selected || step3.selected ? styles.hideHight : {})}}>
                    <SelectList
                        placeholder={"Select level"}
                        setSelected={(val) => setLevel(val)}
                        data={selectLevel}
                        save="value"
                    />
                        <SelectList
                            placeholder={"Select position"}
                        setSelected={(val) => setPosition(val)}
                        data={selectPosition}
                        save="value"
                    />
                        <SelectList
                            placeholder={"Select height"}
                        setSelected={(val) => setHeight(val)}
                        data={selectHeight}
                        save="value"
                    />
                        <SelectList
                            placeholder={"Select weight"}
                        setSelected={(val) => setWeight(val)}
                        data={selectWeight}
                        save="value"
                    />
                </View>
                {!searchDone && level.length > 0 && postition.length > 0 && height.length > 0 && weight.length > 0 &&
                    <button
                        style={styles.button}
                        onClick={() => search() }
                        // title="Start now"
                        // color="#FFC90B"
                        // accessibilityLabel=""
                    >{"Start search"}</button>
                }
            </View>
            </>}
            <Items search={"Hockey stick"} items={results} loading={loading} assets={assets}/>

        </View>
    );
}

const styles = StyleSheet.create({
    tilesContainer: {
        marginLeft: 36,
        transition: '1s',
        overflow: "hidden",
    },
    stepContainer: {
        flexDirection: 'row',
        transition: '1s',
    },
    hideHight: {
        height: 0,
    },
    stepDecriptionSelected: {
        color: '#75BF8A'
    },
    stepText: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 24,
        color: '#454A5F',
    },
    stepTextContainer: {
        marginLeft: 36,
        flexDirection: 'row',
    },
    stepBold: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 20,
        color: '#888888'
    },
    stepDescription: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 20,
        color: '#888888'
    },
    stepsLinkGrey: {
        border: '2px solid #D5D5D5',
    },
    stepsLink: {
        height: '100%',
        left: 10,
        top: 24,
        position: 'absolute',
        // backgroundColor: '#FFB001',
        border: '2px solid #FFB001',
        // transform: 'rotate(-90deg)',
        alignSelf: 'stretch',
        flex: 'none',
        order: '1',
    },
    numberWaiting: {
        border: '2px solid #FFB001',
        color: '#FFB001',
        backgroundColor: '#FFFFFF',
    },
    numberSelected: {
        border: '2px solid #FFB001',
        backgroundColor: '#FFB001',
        color: '#FFFFFF',
    },
    numberGray: {
        border: '2px solid #D5D5D5',
        backgroundColor: '#D5D5D5',
        color: '#FFFFFF',
    },
    number: {
        padding: '6px 12px',
        marginRight: 12,

        width: 24,
        height: 24,

        borderRadius: 90,

        fontWeight: 500,
        fontSize: 14,
        lineHeight: 20,
        /* identical to box height, or 143% */

        textAlign: "center"
    },
    button: {
        marginTop: 40,
        width: 151,
        height: 40,
        backgroundColor: '#FFC90B',
        boxShadow: '0px 8px 22px rgba(157, 128, 26, 0.2)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        fontWeight: 700,
        fontSize: 18,
        lineHeight: 24,
        color: '#FFFFFF',
        borderStyle: 'undefined',
        borderColor: '#FFC90B',
    },
    baseText: {
        width: 292,
        fontFamily: 'sans-serif',
        fontStyle: "normal",
        color: '#454A5F',
    },
    titleContainer: {
        marginBottom: 16,
        marginTop: 30,
    },
    titleText: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 22,
    },
    text: {
        lineHeight: 20,
        fontSize: 14,
        textAlign: 'justify',
        fontWeight: 400,
    }
});
