import {ActivityIndicator, Button, Image, Linking, StyleSheet, Text, View} from "react-native";
import {useAssets} from "expo-asset";

export default function Items ({search, items, loading, assets}) {
    //     {
    //         "model": "CCM Super Tacks AS3 Pro",
    //         "weight": "490g",
    //         "length": "63 inches",
    //         "brand": "CCM Hockey"
    //     },
    const handlePress = (item) => {
        let url = "https://www.amazon.com/s?k=" + search +" " + item.brand+ " " + item.model+ " " + item.length + " " + item.weight;
        Linking.canOpenURL(url).then((supported) => {
            return Linking.openURL(url);
        })
    }


    return (
        <View style={{minWidth: 400}}>
            {loading && <ActivityIndicator size="large"/>}
            {items.length > 0 && <>
                <View>
                <Text style={stylesLink.searchResultResults} numberOfLines={6}>{"("+ items.length + " results)"}</Text>
                </View>
            </>}
            {items.map(
                (item) =><>
                    <View key={item.model} style={stylesLink.container}>
                        <View style={stylesLink.containerDescription}>
                            <Image style={stylesLink.mainPicture} source={assets[1]}></Image>
                            <View style={stylesLink.textContainer}>
                            <Text style={stylesLink.textTop} numberOfLines={6}>{ (item.brand + ", " + item.model) }</Text>
                            <Text style={stylesLink.text} numberOfLines={6}>{ (item.brand + ", " + item.model + ", " + item.length + ", " + item.weight) }</Text>
                            </View>
                        </View>
                        {/*<Image*/}
                        {/*    style={stylesLink.tinyLogo}*/}
                        {/*    source={{*/}
                        {/*        uri: 'https://1000logos.net/wp-content/uploads/2016/10/Amazon-logo-meaning.jpg',*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<Button title={"Link"} onPress={ () => handlePress(item)}/>*/}
                        <button
                            style={stylesLink.button}
                            onClick={() => handlePress(item) }
                            // title="Start now"
                            // color="#FFC90B"
                            // accessibilityLabel=""
                        ><Image style={stylesLink.brandIcon} source={assets[2]}></Image> <Text style={stylesLink.iconText} numberOfLines={6}>{"Visit Amazon.com"}</Text></button>
                    </View>
                    <View style={{alignItems: "center", width: 327}}>
                        <View style={stylesLink.devider}></View>
                    </View>
                </>
            )}
        </View>
    );
}

const stylesLink = StyleSheet.create({
    searchResultText: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 22,
        color: '#313545',

        flex: 'none',
        order: 0,
        flexGrow: 0,
    },
    searchResultResults: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 18,
        color: '#747980',
        flex: 'none',
        order: 1,
        flexGrow: 0,
    },
    devider: {
        width: 216,
        height: 0,
        marginTop: 10,
        border: '0.5px solid rgba(169, 209, 239, 0.5)',
    },
    textContainer: {
        width: 215,
    },
    textTop: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: 14,
        color: '#FFB001',
        marginBottom: 6
    },
    text: {
        color: '#708492',
        lineHeight: 16,
        fontSize: 12,
        textAlign: 'justify',
        fontWeight: 400,
    },
    brandIcon: {
        width: 24,
        height: 24,
        borderRadius: 4,
        marginRight: 5,
        marginTop: 3,
        display: "inline-block",
    },
    iconText: {
        marginTop: 5,
        fontWeight: 600,
        fontSize: 12,
        lineHeight: 16,
        textAlign: 'center',
        color: '#F8FBFF',
        flex: 'none',
        order: '1',
        flexGrow: 0,
    },
    button: {
        marginTop: 4,
        width: 327,
        height: 32,
        justifyContent: "center",
        backgroundColor: '#FFB001',
        border: '0 solid #FFB001',
        borderRadius: 8,
        textAlign: 'center',
        /* Inside auto layout */
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems:  'flex-start',
    },

    mainPicture: {
        width: 104,
        height: 104,
        border: '1px solid #FFB001',
        borderRadius: 16,
    },
    tinyLogo: {
        width: 70,
        height: 70,
        borderRadius: 10,
    },
    resultsText: {
        textAlign: 'center',
        marginBottom: 5,
        fontSize: 20,
    },
    linkDescription: {
        textAlign: 'left',
        margin: 5,
        fontSize: 16,
    },
    containerDescription: {
        display: 'flex',
        flexDirection: 'row',
        alignItems:  'flex-start',
        padding: 0,
        gap: 8,
        isolation: 'isolate',
        // borderStyle: "solid",
        // borderColor: "black",
        // borderRadius: 10,
        // borderWidth: 1,
        width: 327,
        height: 104
    },
    container: {
        marginTop: 10,
        // marginBottom: 5,
        // flex: 1,
        // borderStyle: "solid",
        // borderColor: "black",
        // borderRadius: 10,
        // borderWidth: 1,
        // backgroundColor: '#fff',
        // flexDirection: "row",
        // alignItems: 'left',
        // justifyContent: 'flex-start',
        // maxWidth: 327
    }
});
