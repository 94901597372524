import { useAssets } from 'expo-asset';
import {
    Image,
    StyleSheet, Text,
    View,

} from 'react-native';

export default function Logo() {
    const [assets, assetsError] = useAssets([require('../assets/logo_small.jpg')]);
    return (
        <View>
            {assets && <Image style={styles.logoMain} source={assets[0]}></Image>}
        </View>
    );
}

const styles = StyleSheet.create({
    logoMain: {
       height:24,
        resizeMode: 'contain',
        position: "absolute",
        left: -50,
        top: -50
    },
});
