import { useAssets } from 'expo-asset';
import {
    Image,
    StyleSheet, Text, TouchableOpacity,
    View,

} from 'react-native';

export default function SportTile({onPress}) {
    const [assets, assetsError] = useAssets([require('../assets/hokeyTile.png')]);
    return (
        <TouchableOpacity style={styles.container} onPress={() => onPress()}>
            {assets && <Image style={styles.icon} source={assets[0]}></Image>}
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {

    },
    icon: {
        height: 85,
        width: 85
    },
});
